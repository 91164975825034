<template>
  <div class="service-item-room-service">
    <div class="header">
      <img class="icon" :src="service.avatar" v-if="showIcon" />

      <div class="info">
        <div class="title">{{ service.title }}</div>
        <div class="description">{{ service.description }}</div>
      </div>

      <md-icon class="expand">{{
        expanded ? 'expand_less' : 'expand_more'
      }}</md-icon>

      <div class="clickable" @click="expanded = !expanded" />
    </div>
    <div class="submenu" :class="{ expanded }">
      <div class="description" v-html="description" v-html-reactive-links />
      <b-button
        class="button"
        variant="outline-dark"
        @click="go"
        v-if="buttonLabel != ''"
        >{{ buttonLabel }}</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ['service'],
  data() {
    return {
      expanded: false,
      description: '',
      buttonLabel: '',
    }
  },
  methods: {
    go() {
      this.$router.push(`/service/${this.service.id}`)
    },
  },
  computed: {
    showIcon() {
      return this.service.avatar && !this.service.avatar.endsWith('/')
    },
  },
  watch: {
    service: {
      deep: true,
      immediate: true,
      handler(service) {
        this.$api.get(`services/${service.id}`).then(res => {
          this.buttonLabel = ''
          this.description = ''
          res.data.data.serviceDetails.forEach(detail => {
            if (detail['@type'] == 'DESCRIPTION') {
              this.description = detail.description
                .replace(/\r\n/g, '<br>')
                .replace(/\r\n/g, '<br>')
                .replace(/\n/g, '<br>')
                .replace(/\r/g, '<br>')
            }
            if (detail['@type'] == 'OFFER') {
              this.buttonLabel = detail.offer.description.toUpperCase()
            }
          })
        })
      },
    },
  },
}
</script>

<style lang="scss">
.service-item-room-service {
  border-bottom: 1px #f7f0f0 solid;

  .header {
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    > .icon {
      width: 20px;
      height: 20px;
      margin-left: 15px;
    }
    > .info {
      flex: 1;
      margin-left: 15px;
      > .title {
        color: rgb(120, 120, 120);
        font-size: 14px;
        margin: 0;
        text-transform: uppercase;
        margin-bottom: 1px;
        padding-right: 60px;
      }
      > .description {
        color: rgb(100, 100, 100);
        font-size: 14px;
        margin-bottom: 5px;
      }
    }

    > .expand {
      margin-right: 15px;
    }

    > .clickable {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      transition: 0.2s all;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  > .submenu {
    transition: 0.2s all;
    max-height: 0;
    opacity: 0;

    &.expanded {
      max-height: 600px;
      opacity: 1;
    }

    .description {
      padding: 15px 40px;
    }
    .button {
      margin: 0 15px 15px 40px;
      text-transform: uppercase;
    }
  }
}
</style>

<template>
  <div class="place-map">
    <div class="editor" ref="target"></div>
    <div class="floating">
      <div
        class="service-time-sorter-window"
        :class="{ hide: fullServices.length == 0 }"
        v-if="useDateTimePicker"
      >
        <ServiceTimeSorter
          :category="category"
          :services="fullServices"
          @availableServicesSort="onAvailableServicesSort"
          v-if="fullServices.length > 0 && placeEditor"
          @selectedDay="day => (selectedDay = day)"
        />
      </div>
      <div class="hint-window" v-if="hint && showHint">
        <div class="text" v-html="hint" v-html-reactive-links />
        <div class="fade-gradient" />
        <div class="close-btn" @click="closeHint">
          <md-icon>close</md-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceTimeSorter from '@/components/ServiceTimeSorter'
import SetPredefinedAttrTimeValue from '@/mixins/SetPredefinedAttrTimeValue'

export default {
  props: ['place', 'services', 'hint', 'category'],
  mixins: [SetPredefinedAttrTimeValue],
  components: { ServiceTimeSorter },
  data() {
    return {
      placeEditor: null,
      fullServices: [],

      selectedDay: null,
    }
  },
  async created() {
    if (this.useDateTimePicker) await this.fetchFullServices()
  },
  beforeDestroy() {
    this.placeEditor = null
    window.cncrgPlaceEditor?.destroy()
  },
  methods: {
    async initPlaceEditor() {
      await this.$waitFor(() => this.$refs.target)
      this.placeEditor = await window.initCncrgPlaceEditor({
        viewMode: true,
        place: this.place,
        targetEl: this.$refs.target,
        language: window.userLocale,
        theme: 'light',
        serverUrl: process.env.VUE_APP_PLACE_EDITOR_URL,
        services: this.services.map(service => ({
          ...service,
          available: this.useDateTimePicker ? false : undefined,
          disableAvailableOutline: true,
        })),
      })
      this.placeEditor.onAction(this.handleAction)
    },
    async fetchFullServices() {
      this.fullServices = await Promise.all(
        this.services.map(async service => {
          return (await this.$api.get('/services/' + service.id)).data.data
        }),
      )
    },
    handleAction(action) {
      switch (action.type) {
        case 'open-service':
          this.handleOpenService(action.service)
          break
      }
    },
    handleOpenService(serviceId) {
      if (this.useDateTimePicker) {
        this.setPredefinedAttrTimeValue(
          this.fullServices.find(s => s.id == serviceId),
        )
      }
      this.$router.push('/service/' + serviceId)
    },
    onAvailableServicesSort({ availableServices, notAvailableServices }) {
      this.placeEditor.patchOptions({
        services: this.services.map(service => {
          const isAvailable = !!availableServices.find(s => s.id == service.id)
          const isNotAvailable = !!notAvailableServices.find(
            s => s.id == service.id,
          )

          let status = isAvailable
          if (!isAvailable && !isNotAvailable) status = undefined

          return {
            ...service,
            available: status,
            disableAvailableOutline: false,
          }
        }),
      })
    },
    closeHint() {
      this.$store.placeCategoriesWithClosedHint.push(this.category.id)
    },
  },
  watch: {
    placeEditor: {
      immediate: true,
      async handler(placeEditor) {
        if (!placeEditor) await this.initPlaceEditor()
      },
    },
  },
  computed: {
    showHint() {
      return !this.$store.placeCategoriesWithClosedHint.includes(
        this.category.id,
      )
    },
    useDateTimePicker() {
      return !!this.category.props?.datetimepicker
    },
  },
}
</script>

<style lang="scss">
.place-map {
  flex: 1;
  position: relative;

  > .editor {
    height: 100%;
  }

  > .floating {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > * {
      pointer-events: initial;
    }

    > .service-time-sorter-window {
      align-self: center;
      background-color: white;
      border-radius: 14px;
      padding: 10px 15px;
      box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);
      transition: 0.25s all;

      opacity: 1;
      transform: translateY(0);
      &.hide,
      &:not(:has(> .service-time-sorter)) {
        opacity: 0;
        transform: translateY(-50px);
      }
    }

    > .hint-window {
      position: relative;
      align-self: center;
      background-color: white;
      border-radius: 32px;
      padding: 0 14px;
      padding-top: 32px;
      padding-bottom: 5px;
      max-height: 160px;
      margin: 15px;
      display: flex;
      box-shadow: 0 6px 9px 1px rgba(0, 0, 0, 0.2);

      > .text {
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      > .fade-gradient {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: inherit;
        pointer-events: none;
        background: linear-gradient(to bottom, transparent 70%, white);
      }

      > .close-btn {
        position: absolute;
        right: 0px;
        top: -15px;
        background-color: #979797;
        color: white;
        border-radius: 100%;
        border: 5px solid white;
        padding: 5px;
        box-shadow: 0 1px 11px 1px #0003;
        cursor: pointer;

        > .md-icon {
          color: inherit;
        }
      }
    }
  }
}
</style>

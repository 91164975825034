<template>
  <div
    class="service-item-room-breakfast"
    @click="go"
    ref="root"
    :style="{
      opacity: backgroundImageSize != 'auto' ? '1' : '0.5',
      '--image-height': imageHeight + 'px',
      '--background-src': `url(${service.avatar})`,
      '--background-size': backgroundImageSize,
      '--background-position': backgroundImagePosition,
      '--background-position-y': bgPosY,
      '--ribbon-y-pos': ribbonYPos,
    }"
  >
    <div class="ribbon">
      <div class="info">
        <div class="title">{{ service.title }}</div>
        <div class="description" v-html="service.description" />
      </div>
    </div>
    <div class="image" />
  </div>
</template>

<script>
export default {
  props: ['service', 'last'],
  data() {
    return {
      imageHeight: 180,
      width: 0,
      height: 0,
      bgPosX: 0,
      bgPosY: 0,
      backgroundImageSize: 'auto',
    }
  },
  mounted() {
    this.updateVariables()
  },
  beforeDestroy() {
    clearInterval(this.needToCalculateBackgroundInterval)
  },
  methods: {
    go() {
      this.$router.push(`/service/${this.service.id}`)
    },
    async updateVariables() {
      await this.$waitFor(() => this.$refs.root)
      const root = this.$refs.root

      const rect = root.getBoundingClientRect()
      this.width = rect.width

      this.imageHeight = window.innerHeight - rect.top - 60
      if (this.imageHeight < 180 || !this.last) this.imageHeight = 180
      if (this.imageHeight > 550) this.imageHeight = 550

      this.height = this.imageHeight + 60

      this.calculateBackground()
    },
    calculateBackground() {
      const containerAspectRatio = this.width / this.height
      const image = new Image()
      image.src = this.service.avatar
      image.onload = () => {
        const imageAspectRatio = image.width / image.height
        let bgSize, bgPosX, bgPosY

        if (containerAspectRatio > imageAspectRatio) {
          // Scale based on container width
          const scaleWidth = this.width / image.width
          const scaledHeight = image.height * scaleWidth
          bgSize = `${this.width}px ${scaledHeight}px`

          // Check if the scaled height is not enough to cover the container height
          if (scaledHeight < this.height) {
            // Recalculate to ensure height is covered
            const scaleHeight = this.height / image.height
            const scaledWidth = image.width * scaleHeight
            bgSize = `${scaledWidth}px ${this.height}px`
            bgPosX = `${(this.width - scaledWidth) / 2}px`
            bgPosY = '0px'
          } else {
            bgPosY = `${(this.height - scaledHeight) / 2}px`
            bgPosX = '0px'
          }
        } else {
          // Scale based on container height
          const scaleHeight = this.height / image.height
          const scaledWidth = image.width * scaleHeight
          bgSize = `${scaledWidth}px ${this.height}px`

          // Check if the scaled width is not enough to cover the container width
          if (scaledWidth < this.width) {
            // Recalculate to ensure width is covered
            const scaleWidth = this.width / image.width
            const scaledHeight = image.height * scaleWidth
            bgSize = `${this.width}px ${scaledHeight}px`
            bgPosY = `${(this.height - scaledHeight) / 2}px`
            bgPosX = '0px'
          } else {
            bgPosX = `${(this.width - scaledWidth) / 2}px`
            bgPosY = '0px'
          }
        }

        this.backgroundImageSize = bgSize
        this.bgPosX = bgPosX
        this.bgPosY = bgPosY
      }
    },
    _resize() {
      this.updateVariables()
    },
  },
  computed: {
    ribbonYPos() {
      return parseInt(this.bgPosY) - 60 + 'px'
    },
    backgroundImagePosition() {
      return `${this.bgPosX} ${this.bgPosY}`
    },
  },
}
</script>

<style lang="scss">
.service-item-room-breakfast {
  display: flex;
  flex-direction: column;
  margin-top: -65px;

  > .ribbon {
    display: flex;
    justify-content: space-between;
    border-top: 1px white solid;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 60px;
    width: 350px;
    padding: 0 15px;
    padding-top: 10px;
    cursor: pointer;

    .info {
      color: white;
      font-weight: 300;
      .title {
        font-family: Oswald;
        font-size: 23px;
      }
      .description {
        font-size: 15px;
        margin-top: 7px;
      }
    }
  }

  > .image {
    cursor: pointer;
    width: 100%;
    height: var(--image-height);
  }

  .image,
  .ribbon {
    background-repeat: no-repeat;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), transparent 120px),
      var(--background-src);
    background-size: var(--background-size);
    background-position: var(--background-position);

    &.image {
      background-position-y: -60px, var(--ribbon-y-pos);
    }

    &.ribbon {
      background-position-y: 0, var(--background-position-y);
    }
  }
}
</style>

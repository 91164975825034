<template>
  <div class="service-time-sorter" v-if="availableDays.length > 0">
    <div class="month-selector">
      <div class="arrow" @click="selectDay(-30)">
        <md-icon>arrow_left</md-icon>
      </div>

      <div class="title">{{ dayMonthTitle }}</div>

      <div class="arrow" @click="selectDay(+30)">
        <md-icon>arrow_right</md-icon>
      </div>
    </div>

    <div class="day-selector">
      <div class="arrow" @click="selectDay(-1)">
        <md-icon>arrow_left</md-icon>
      </div>

      <div
        class="day"
        :class="{
          active: offset == 0,
          disabled: !isDayAvailableByOffset(offset),
        }"
        v-for="offset in [-2, -1, 0, 1, 2]"
        @click="selectDay(offset)"
        :key="offset"
      >
        <div class="day">
          {{ getDisplayDay(offset) }}
        </div>

        <div
          class="weekday"
          :class="{
            weekend: isWeekend(offset),
          }"
        >
          {{ getDisplayWeekday(offset) }}
        </div>
      </div>

      <div class="arrow" @click="selectDay(1)">
        <md-icon>arrow_right</md-icon>
      </div>
    </div>
  </div>
  <div class="service-time-sorter error" v-else>
    <md-icon>warning</md-icon>
    {{ $t('error.noAvailableDays') }}
  </div>
</template>

<script>
import { formatDate } from '../lib/formatDate'

export default {
  props: ['services', 'category'],
  data() {
    return {
      currentDay: null,
    }
  },
  created() {
    this.currentDay = this.availableDays[0]

    if (this.$store.placeCategoriesTimeSaves[this.category.id]) {
      const { day } = this.$store.placeCategoriesTimeSaves[this.category.id]
      this.currentDay = day
    }
  },
  methods: {
    sortAvailableServices() {
      const availableServices = []
      const notAvailableServices = []

      for (const service of this.services) {
        const offer = service.serviceDetails.find(
          detail => detail['@type'] == 'OFFER',
        )?.offer
        if (!offer) continue

        const attr = offer.attributes.find(attr =>
          ['date_time', 'date', 'slots'].includes(attr['@type']),
        )
        if (!attr) continue

        this.isAnySlotForCurrentDayAvailable(attr)
          ? availableServices.push(service)
          : notAvailableServices.push(service)
      }

      this.$emit('availableServicesSort', {
        availableServices,
        notAvailableServices,
      })
    },
    isAnySlotForCurrentDayAvailable(attr) {
      if (['date', 'date_time'].includes(attr['@type'])) {
        if (!attr.range[this.currentDay]) return false
        if (Object.keys(attr.range[this.currentDay]).length == 0) return false
        return true
      }

      if (attr['@type'] == 'slots') {
        if (!attr.slots[this.currentDay]) return false
        if (!attr.slots[this.currentDay].find(slot => !slot.forbidden))
          return false
        return true
      }
    },
    emitSelectedTime() {
      this.$emit('selectedDay', this.currentDay)

      if (!this.$store.placeCategoriesTimeSaves[this.category.id])
        this.$store.placeCategoriesTimeSaves[this.category.id] = {}
      this.$store.placeCategoriesTimeSaves[this.category.id] = {
        day: this.currentDay,
      }
    },
    getOffsetDay(offset = 0, currentDay = this.currentDay) {
      const offsetDate = new Date(currentDay)
      offsetDate.setDate(offsetDate.getDate() + offset)
      return formatDate(offsetDate)
    },
    selectDay(offset) {
      let finalDay = this.getOffsetDay(offset)
      const firstAvailable = this.availableDays[0]
      const lastAvailable = this.availableDays[this.availableDays.length - 1]

      //если день за границами,
      //то выбираем наиболее близкий
      if (new Date(finalDay) < new Date(firstAvailable))
        finalDay = firstAvailable
      if (new Date(finalDay) > new Date(lastAvailable)) finalDay = lastAvailable

      //если этот день в списке запрещен,
      //то выбираем наиболее близкий
      if (!this.isDayAvailable(finalDay)) {
        const dayDistanceSortFunc = (dayA, dayB) => {
          const dayADistance = Math.abs(new Date(dayA) - new Date(finalDay))
          const dayBDistance = Math.abs(new Date(dayB) - new Date(finalDay))
          return dayADistance - dayBDistance
        }

        const mostCloseLeftDays = [...this.availableDays]
          .filter(day => new Date(day) < new Date(finalDay))
          .sort(dayDistanceSortFunc)
        const mostCloseRightDays = [...this.availableDays]
          .filter(day => new Date(day) > new Date(finalDay))
          .sort(dayDistanceSortFunc)

        const rightDirection = offset > 0
        finalDay = rightDirection ? mostCloseRightDays[0] : mostCloseLeftDays[0]
      }

      this.currentDay = finalDay
    },
    isDayAvailable(day) {
      return this.availableDays.includes(day)
    },
    isDayAvailableByOffset(offset) {
      return this.isDayAvailable(this.getOffsetDay(offset))
    },
    getDisplayDay(offset) {
      const date = new Date(this.getOffsetDay(offset))
      return date.toLocaleString(this.$t('datetimeFormat'), {
        day: '2-digit',
      })
    },
    getDisplayWeekday(offset) {
      const date = new Date(this.getOffsetDay(offset))
      return date.toLocaleString(this.$t('datetimeFormat'), {
        weekday: 'short',
      })
    },
    isWeekend(offset) {
      const date = new Date(this.getOffsetDay(offset))
      return [0, 6].includes(date.getDay())
    },
  },
  watch: {
    currentDay() {
      this.emitSelectedTime()
      this.sortAvailableServices()
    },
  },
  computed: {
    availableDays() {
      const availableDays = new Set()

      for (const service of this.services) {
        const offer = service.serviceDetails.find(
          detail => detail['@type'] == 'OFFER',
        )?.offer
        if (!offer) continue

        const timeAttributes = offer.attributes.filter(attr =>
          ['date_time', 'date', 'slots'].includes(attr['@type']),
        )

        for (const attr of timeAttributes) {
          const attrAvailableDays = Object.keys(attr.range || attr.slots)
          attrAvailableDays.forEach(day => availableDays.add(day))
        }
      }

      return Array.from(availableDays).sort()
    },
    dayMonthTitle() {
      const date = new Date(this.currentDay)
      return (
        date.toLocaleString(this.$t('datetimeFormat'), {
          month: 'long',
        }) +
        ' ' +
        date.getFullYear()
      )
    },
  },
}
</script>

<style lang="scss">
.service-time-sorter {
  transition: 0.5s all;
  @starting-style {
    transform: scale(0.9);
  }

  &.error {
    &,
    .md-icon {
      color: red;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 19px;
    font-weight: 500;
    text-align: center;
    padding: 5px;
  }
  > .month-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    > .title {
      min-width: 150px;
      text-align: center;
    }
    > .arrow {
      cursor: pointer;
    }
  }

  > .day-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ccc;
    padding-top: 5px;

    > .day {
      cursor: pointer;
      padding: 4px 12px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &.active {
        cursor: default;
        font-size: 19px;
        > .day {
          font-weight: 500 !important;
        }
      }
      &.disabled {
        cursor: default;
        opacity: 0.5;
      }

      > .day {
        font-weight: 400 !important;
      }

      > .weekday {
        &.weekend {
          -webkit-text-fill-color: red;
          color: red;
        }
      }
    }

    > .arrow {
      cursor: pointer;
    }
  }
}
</style>

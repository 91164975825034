var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"root",staticClass:"service-item-room-breakfast",style:({
    opacity: _vm.backgroundImageSize != 'auto' ? '1' : '0.5',
    '--image-height': _vm.imageHeight + 'px',
    '--background-src': ("url(" + (_vm.service.avatar) + ")"),
    '--background-size': _vm.backgroundImageSize,
    '--background-position': _vm.backgroundImagePosition,
    '--background-position-y': _vm.bgPosY,
    '--ribbon-y-pos': _vm.ribbonYPos,
  }),on:{"click":_vm.go}},[_c('div',{staticClass:"ribbon"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.service.title))]),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.service.description)}})])]),_c('div',{staticClass:"image"})])}
var staticRenderFns = []

export { render, staticRenderFns }
<script>
export default {
  methods: {
    setPredefinedAttrTimeValue(service) {
      let predefinedAttrId = null
      let predefinedAttrValue = null

      const offer = service.serviceDetails.find(
        detail => detail['@type'] == 'OFFER',
      )?.offer
      if (!offer) return

      const attr = offer.attributes.find(attr =>
        ['date_time', 'date', 'slots'].includes(attr['@type']),
      )
      if (!attr) return
      predefinedAttrId = attr.id

      if (['date_time', 'date'].includes(attr['@type'])) {
        const selectedDate = new Date(this.selectedDay)
        selectedDate.setHours(0)
        selectedDate.setMinutes(0)
        selectedDate.setSeconds(0)
        selectedDate.setMilliseconds(0)

        if (attr['@type'] == 'date_time') {
          const dayRange = attr.range[this.selectedDay]
          const hour = Object.keys(dayRange)[0]
          const minute = hour[0]
          selectedDate.setHours(hour)
          selectedDate.setMinutes(minute)
        }

        predefinedAttrValue = selectedDate
      }

      if (['slots'].includes(attr['@type'])) {
        predefinedAttrValue = new Date(this.selectedDay)
      }

      this.$waitFor(
        () =>
          this.$store.attributesSaves[predefinedAttrId]?.anyComponentIsExist,
      ).then(() => {
        this.$store.attributesSaves[predefinedAttrId].setValue(
          predefinedAttrValue,
        )
      })
    },
  },
}
</script>

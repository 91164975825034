<template>
  <component
    :is="this.components[this.service.type]"
    :service="service"
    :last="last"
  />
</template>

<script>
import RoomService from '@/components/ServiceItems/RoomService'
import RoomBreakfast from '@/components/ServiceItems/RoomBreakfast'

export default {
  components: { RoomService, RoomBreakfast },
  props: ['service', 'last'],
  data() {
    return {
      components: {
        ROOM_SERVICE: RoomService,
        FOOD: RoomBreakfast,
        FOOD2: RoomBreakfast,
      },
    }
  },
}
</script>

<template>
  <Loading v-if="!category" />
  <div id="services-view" v-else>
    <TopMenu
      :showLangSelector="true"
      :title="category.title"
      :avatar="
        !['MAP', 'FOOD', 'FOOD2'].includes(category.type)
          ? category.avatar
          : null
      "
      :customBack="
        () => {
          $router.push('/hotel/' + category.propertyId)
        }
      "
    />
    <div
      class="content"
      :class="{ fullscreen: ['MAP'].includes(category.type) }"
    >
      <div
        class="food-spacer1"
        v-if="['FOOD', 'FOOD2'].includes(category.type)"
      />

      <p
        class="description"
        v-if="!['MAP'].includes(category.type) && category.description"
        v-html="category.description"
        v-html-reactive-links
      />

      <div
        class="food-spacer2"
        v-if="['FOOD', 'FOOD2'].includes(category.type)"
      />

      <div
        class="services"
        v-if="['ROOM_SERVICE', 'FOOD', 'FOOD2'].includes(category.type)"
      >
        <ServiceItem
          v-for="(service, k) in category.services"
          :last="k == category.services.length - 1"
          :service="service"
          :key="service.id"
        />
      </div>
      <PlaceMap
        v-else-if="category.type == 'MAP'"
        :category="category"
        :place="category.props.template"
        :services="category.services"
        :hint="category.description"
      />
      <CategoryCards :category="category" v-else />
    </div>
  </div>
</template>

<script>
import ServiceItem from '@/components/ServiceItem'
import TopMenu from '@/components/TopMenu'
import PlaceMap from '@/components/PlaceMap.vue'
import CategoryCards from '@/components/CategoryCards.vue'

export default {
  props: ['id', 'stay'],
  components: { ServiceItem, TopMenu, PlaceMap, CategoryCards },
  data() {
    return {
      category: null,
    }
  },
  mounted() {
    if (this.stay == undefined) {
      this.$api.get(`service-categories/${this.id}`).then(res => {
        this.category = res.data.data
        this.$events.emit('set-theme', this.category.style ?? 'default')
        this.$store.sendUTMIfNotAlready({
          utmSource: this.$store.isIframe ? 'widget' : 'hotel',
          utmContent: this.category.propertyId,
          utmMedium: 'category',
        })

        if (this.category.services.length == 1) {
          this.$router.replace('/service/' + this.category.services[0].id)
          this.$store.routerHistory.pop()
        }
      })
    } else {
      this.$api.get('hotels/' + this.stay).then(res => {
        let hotel = res.data.data

        hotel.stay.serviceCategories.forEach(serviceCategory => {
          if (serviceCategory.id == this.id) this.category = serviceCategory
        })
      })
    }
  },
}
</script>

<style lang="scss">
#services-view {
  height: 100%;
  > .content {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: -17px;
    padding-top: 15px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: white;
    width: 100%;

    &.fullscreen {
      height: 100%;
      margin-top: 0;
      padding-top: 50px;
    }

    > .description {
      padding: 0 20px;
      text-align: justify;
      font-weight: 300;
    }

    > .food-spacer1 {
      height: 70px;
    }
    > .food-spacer2 {
      height: 85px;
    }
  }
}
</style>

<template>
  <div class="category-cards" :class="{ loading }">
    <Loading v-if="loading" />
    <div class="tags" v-if="category.tags.length > 0">
      <span
        v-for="tag in category.tags"
        class="tag"
        :class="{ active: activeTagsIds.includes(tag.id) }"
        @click="clickTag(tag)"
        :key="tag.id"
      >
        {{ tag.title }}
      </span>
    </div>

    <div class="service-time-sorter-window" v-if="useDateTimePicker">
      <ServiceTimeSorter
        :category="category"
        :services="fullServices"
        @availableServicesSort="onAvailableServicesSort"
        v-if="fullServices.length > 0"
        @selectedDay="day => (selectedDay = day)"
      />
    </div>

    <div class="cards">
      <Card
        :key="id"
        v-for="(service, id) in services"
        :label="service.title"
        :image="service.avatar"
        :disabled="!availableServicesIds.includes(service.id)"
        :subtitle="
          !availableServicesIds.includes(service.id)
            ? $t('notAvailableForSelectedDate')
            : null
        "
        @click.native="
          if (availableServicesIds.includes(service.id))
            handleOpenService(service.id)
        "
      />
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card'
import ServiceTimeSorter from '@/components/ServiceTimeSorter'
import SetPredefinedAttrTimeValue from '@/mixins/SetPredefinedAttrTimeValue'

export default {
  components: { Card, ServiceTimeSorter },
  mixins: [SetPredefinedAttrTimeValue],
  props: ['category'],
  data() {
    return {
      activeTags: [],
      services: [],
      fullServices: [],
      availableServicesIds: [],

      selectedDay: null,
    }
  },
  async created() {
    this.services = this.category.services
    if (this.useDateTimePicker) await this.fetchFullServices()

    this.availableServicesIds = this.useDateTimePicker
      ? []
      : this.services.map(service => service.id)
  },
  methods: {
    async fetchFullServices() {
      this.fullServices = await Promise.all(
        this.services.map(async service => {
          return (await this.$api.get('/services/' + service.id)).data.data
        }),
      )
    },
    clickTag(tag) {
      const alreadyExist = !!this.activeTags.find(actTag => actTag.id == tag.id)
      if (!alreadyExist) this.activeTags.push(tag)
      else
        this.activeTags = this.activeTags.filter(actTag => actTag.id != tag.id)
    },
    handleOpenService(serviceId) {
      if (this.useDateTimePicker) {
        this.setPredefinedAttrTimeValue(
          this.fullServices.find(s => s.id == serviceId),
        )
      }
      this.$router.push('/service/' + serviceId)
    },
    async onAvailableServicesSort({ notAvailableServices }) {
      await this.$waitFor(() => this.fullServices.length > 0)
      this.availableServicesIds = this.services
        .filter(
          service =>
            !notAvailableServices.find(
              findService => service.id == findService.id,
            ),
        )
        .map(service => service.id)
    },
  },
  computed: {
    activeTagsIds() {
      return this.activeTags.map(tag => tag.id)
    },
    useDateTimePicker() {
      return !!this.category.props?.datetimepicker
    },
    loading() {
      return this.useDateTimePicker && this.fullServices.length == 0
    },
  },
  watch: {
    activeTags(activeTags) {
      let urlAddition = ''

      activeTags.forEach((tag, i) => {
        urlAddition += i == 0 ? `?` : '&'
        urlAddition += `tags=${tag.id}`
      })

      this.$api
        .get(`service-categories/${this.category.id}${urlAddition}`)
        .then(res => {
          this.services = res.data.data.services
        })
    },
  },
}
</script>

<style lang="scss">
.category-cards {
  position: relative;
  display: flex;
  flex-direction: column;

  &.loading {
    max-height: 100px;
    overflow: hidden;
  }

  > .tags {
    text-align: center;

    .tag {
      display: inline-block;
      border: 1px solid rgb(215, 215, 215);
      border-radius: 16px;
      background-color: transparent;
      transition: 0.2s all;
      color: rgb(30, 40, 49);
      padding: 7px 15px 7px 15px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
      -webkit-user-drag: none;

      &:hover {
        background-color: rgb(250, 250, 250);
      }
      &.active {
        background-color: rgb(30, 40, 49);
        color: rgb(215, 215, 215);
      }
    }
  }

  > .service-time-sorter-window {
    margin-top: 15px;
    align-self: center;
    background-color: white;
    border-radius: 14px;
    padding: 10px 15px;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);
  }

  > .cards {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto-fill, 1fr);
    grid-row-gap: 0.5em;
    grid-column-gap: 1em;
  }
}
</style>
